import { Dispatch, SetStateAction, useSyncExternalStore } from 'react';
import * as v from 'valibot';
import { trySync } from '../helpers/try';

type Validate = (value: string) => boolean;

const createLocalStorageStore = <Type,>({
	key,
	parse,
	stringify,
	validate,
}: {
	key: string;
	parse: (value: string | null) => Type | null;
	stringify: (value: Type | null) => string | null;
	validate?: Validate;
}) => {
	const listeners = new Set<(value: Type | null) => void>();
	let itemCache: { value: Type | null; raw: string | null } | null = null;
	const clearStorage = () => {
		itemCache = { value: null, raw: null };
		try {
			window.localStorage.removeItem(key);
		} catch (error) {
			// NOOP
		}
	};
	const validateStorage = (value: string | null) => {
		if (value == null) {
			clearStorage();
			return false;
		}

		if (validate != null && !validate(value)) {
			clearStorage();
			return false;
		}

		return true;
	};
	const getItem = () => {
		if (itemCache != null) {
			if (!validateStorage(itemCache.raw)) {
				return null;
			}
			return itemCache.value;
		}
		try {
			const item = window.localStorage.getItem(key);

			if (!validateStorage(item)) {
				return null;
			}

			const itemValue = parse(item);

			itemCache = { value: itemValue, raw: item };

			return itemValue;
		} catch (error) {
			return null;
		}
	};
	const setItem = (value: Type | null) => {
		const stringified = stringify(value);
		itemCache = { value, raw: stringified };

		try {
			if (stringified != null) {
				window.localStorage.setItem(key, stringified);
			} else {
				window.localStorage.removeItem(key);
			}
		} catch (error) {
			// NOOP
		}
	};
	const getSnapshot = (initialValue: Type) => {
		const item = getItem();

		if (item != null) return item;

		setItem(initialValue);
		return initialValue;
	};
	const getServerSnapshot = () => {
		return null;
	};

	const set: Dispatch<SetStateAction<Type | null>> = (
		value: (Type | null) | ((ondValue: Type | null) => Type | null)
	) => {
		const valueToStore = value instanceof Function ? value(getItem()) : value;

		setItem(valueToStore);

		for (const listener of listeners) {
			listener(valueToStore);
		}
	};

	const subscribe = (listener: () => void) => {
		listeners.add(listener);

		const abortController = new AbortController();
		window.addEventListener(
			'storage',
			(event) => {
				if (event.storageArea === window.localStorage && event.key === key) {
					itemCache = null;
					listener();
				}
			},
			{
				signal: abortController.signal,
			}
		);

		return () => {
			listeners.delete(listener);
			abortController.abort();
		};
	};

	return {
		getSnapshot,
		getServerSnapshot,
		set,
		subscribe,
	};
};

/**
 * useSyncExternalStore to read and write to localStorage
 *
 * It is autmatically synced between multiple tabs
 *
 * On the server the value will always be null
 */
export const useLocalStorageStore = <Type,>(
	store: ReturnType<typeof createLocalStorageStore<Type>>,
	initialValue: Type
) => {
	const value = useSyncExternalStore(
		store.subscribe,
		() => store.getSnapshot(initialValue),
		store.getServerSnapshot
	);

	return [value, store.set] as const;
};

/**
 * Store a string enum in localstorage
 */
export const createLocalStorageStringStore = <
	AllowedValues extends ReadonlyArray<string>
>(
	key: string,
	allowedValues: AllowedValues
) => {
	return createLocalStorageStore({
		key,
		parse: (value) => {
			if (value == null) return null;
			if (allowedValues.includes(value)) return value as AllowedValues[number];
			return null;
		},
		stringify: (value) => value,
	});
};

/**
 * Store Boolean in localstorage
 */
export const createLocalStorageBooleanStore = ({ key }: { key: string }) => {
	return createLocalStorageStore<boolean>({
		key,
		parse: (value) => {
			if (value == null) return null;
			return value === 'true';
		},
		stringify: (value) => {
			return value ? 'true' : 'false';
		},
	});
};

/**
 * Store Boolean in localstorage
 */
export const createExpirableLocalStorageStore = <Type,>({
	key,
	ttlInMs,
	schema,
}: {
	key: string;
	ttlInMs: number;
	schema: v.BaseSchema<unknown, Type, v.BaseIssue<unknown>>;
}) => {
	const expirableSchema = v.object({
		value: schema,
		dateSet: v.pipe(v.string(), v.isoTimestamp()),
	});
	return createLocalStorageStore<Type>({
		key,
		validate: (value) => {
			const unparsed = trySync(() => JSON.parse(value));

			if (!unparsed.success) return false;

			const parsed = v.safeParse(expirableSchema, unparsed.result);

			if (!parsed.success) return false;

			return new Date(parsed.output.dateSet).getTime() + ttlInMs > Date.now();
		},
		parse: (value) => {
			if (!value) return null;

			const unparsed = trySync(() => JSON.parse(value));

			if (!unparsed.success) return null;

			const parsed = v.safeParse(expirableSchema, unparsed.result);

			if (!parsed.success) return null;

			return parsed.output.value ?? null;
		},
		stringify: (value) => {
			return JSON.stringify({
				value,
				dateSet: new Date(Date.now()).toJSON(),
			});
		},
	});
};
