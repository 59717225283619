import { produce } from 'immer';

type Event = {
	category?: string;
	action?: string;
	label?: string;
	value?: number;
};

export type EventData = {
	event?: string;
	events: Event;
	page?: {
		urlPath?: string;
		[key: string]: unknown;
	};
	user?: {
		[key: string]: unknown;
	};
	search?: {
		[key: string]: unknown;
	};
};

export const pushEvent = (eventData: EventData) => {
	const updatedEvent = produce(eventData, (draft) => {
		// set events props to either an actual value or undefined
		draft.events ??= {};
		draft.events.category ??= undefined;
		draft.events.action ??= undefined;
		draft.events.label ??= undefined;
		draft.event ??= draft.events.category;
		// keep old url path if possible
		draft.page ??= {};
		draft.page.urlPath ??= window.location.pathname;
	});
	window.dataLayer ??= [];
	try {
		window.dataLayer.push(updatedEvent);
	} catch (error) {
		// NOOP
	}
};
