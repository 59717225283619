import { HrefKind } from '@/globals/routing/link/functions/getLinkInfo';
import { PageCollection } from '@/globals/dataCollector/pageDataCollector/types/pageDataCollector.types';
import {
	WebApi404Data,
	WebApiPageData,
} from '@/globals/dataCollector/types/dataCollector.types';

export const LOCATION_CHANGE = 'LOCATION_CHANGE';
export const LOCATION_CHANGE_IN_NEW_WINDOW = 'LOCATION_CHANGE_IN_NEW_WINDOW';
export const LOCATION_DATA_LOADED = 'LOCATION_DATA_LOADED';
export const LOCATION_INTERNALLY_REDIRECTED = 'LOCATION_INTERNALLY_REDIRECTED';
export const LOCATION_EXTERNALLY_REDIRECTED = 'LOCATION_EXTERNALLY_REDIRECTED';

export type LinkMeta = {
	linkType: string;
	text?: string;
	dataset?: Record<string, string | undefined>;
};

export type LocationChangeProps = {
	href: string;
	hrefKind: HrefKind;
	linkMeta?: LinkMeta;
};

export const locationChangeInNewWindow = ({
	href,
	hrefKind,
	linkMeta,
}: LocationChangeProps) => ({
	type: LOCATION_CHANGE_IN_NEW_WINDOW,
	href,
	hrefKind,
	linkMeta,
});

export const locationChange = ({
	href,
	hrefKind,
	linkMeta,
}: LocationChangeProps) => ({
	type: LOCATION_CHANGE,
	href,
	hrefKind,
	linkMeta,
});

export type LocationDataLoadedAction = {
	type: typeof LOCATION_DATA_LOADED;
	data: PageCollection<WebApiPageData | WebApi404Data> | undefined;
	redirectHref?: string;
	status: number;
};

export type LocationExternallyRedirectedAction = {
	type: typeof LOCATION_EXTERNALLY_REDIRECTED;
	href: string;
	status: number;
};

export type LocationChangeResultActions =
	| LocationDataLoadedAction
	| LocationExternallyRedirectedAction
	| undefined;

export const locationDataLoaded = ({
	status,
}: Omit<LocationDataLoadedAction, 'type'>) => ({
	type: LOCATION_DATA_LOADED,
	status,
});
